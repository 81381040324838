import React, { useEffect, useState } from 'react';
import { message } from 'antd';

import SEO from '../blocks/layout/seo';
import FormContainer from '../blocks/layout/formContainer';
import { Firebase } from '../config/firebase';
import axios from 'axios';
let breakTheLoop = false;
const Accounts = () => {
    const [accountsList, setAccountsList] = useState([]);

    useEffect(() => {
        // async function loadResults(user) {
        //     const { uid } = user;
        //     if (!breakTheLoop && uid !== undefined) {
        //         breakTheLoop = true;
        //         const { data, error } = await axios.get(
        //             `${process.env.GATSBY_BASE_URL}/auth/accounts`,
        //             {
        //                 params: {
        //                     uid,
        //                 },
        //             }
        //         );
        //         breakTheLoop = false;
        //         setAccountsList(data);
        //     }
        // }
        // Firebase.auth().onAuthStateChanged((user) => {
        //     if (user) {
        //         loadResults(user);
        //     }
        // });
    }, []);
    return (
        <>
            <SEO title="One place for all your forms — FormWithSheet" />
            <FormContainer menuKey="3">
                <h2>Things to finish.</h2>
                {/* {JSON.stringify(accountsList, null, 2)} */}
            </FormContainer>
        </>
    );
};

export default Accounts;
